<template>
  <div class="about">
    <Header />
    <van-swipe :autoplay="3000" class="travel-swiper">
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <img :src="item.url" class="travel-banner" />
      </van-swipe-item>
    </van-swipe>
    <div class="nav-header">
      <div v-if="containerList.length>0" class="title">
        <div v-for="item in containerList" class="title-box" :key="item.id">
          <div class="title-text" @click="handleClickChangeDetails(item)">{{h5Language=='En'?item.name:item.nameEn}}</div>
          <div class="line" v-if="active==item.id"></div>
        </div>
      </div>
    </div>

    <div class="main">
      <van-pull-refresh  v-model="isLoading"  style="min-height: calc(100vh - '260px')" success-text="刷新成功" :head-height="80" @refresh="onRefresh">
        <div v-for="(item,index) in artList" :key="item.id" class="art-class">
            <img  v-if="(index-1)%2!=0" :src="item.imgUrl" alt="" class="left-radus">
            <div class="content-box">
              <div class="box-title">{{h5Language=='En'?item.title:item.titleEn}}</div>
              <div class="box-content">{{h5Language=='En'?item.synopsis:item.synopsisEn}}</div>
              <div class="box-see" v-if="item.contentType!=0" @click="handleClickSeeMore(item)">{{h5Language=='En'?'/ 阅读全文':'/ Read More'}}</div>
            </div>
            <img  v-if="(index-1)%2==0" class="right-radus" :src="item.imgUrl" alt="">
        </div>
        <div class="art-bottom">{{h5Language=='En'?'～ 没有更多数据了 ～':'～ no data ～'}}</div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import Header from '../components/header'
import {cloumnTree, getBanner, getArticleChoose, getArticle} from "../api";
export default {
  name: 'Corebusiness',
  components: {
    Header,
  },
  data(){
    return{
      list:[],
      containerList:[],
      active:35,
      artList:[],
      isLoading: false,
      pn:1,
      ps:4,
      h5Language:''
    }
  },
  watch:{

  },
  mounted() {
    this.h5Language = localStorage.getItem('h5Language')||'En'
    this.cloumnId = this.$route.query.id||''
    if(this.$route.query.listId){
      this.cloumnId = this.$route.query.listId
    }
    this.active = this.$route.query.anchorId||35
    this.cloumnTree()
    this.getBanner()
    this.getArticleChoose()
  },
  methods:{
    onRefresh() {
      setTimeout(() => {
        this.pn = 1
        this.getArticleChoose()
        this.isLoading = false;
      }, 1000);
    },
    getArticleChoose() {
      this.artList = []
      getArticle({cloumnId:this.active, parentId: this.cloumnId,pn:this.pn,ps:this.ps}).then(res => {
        if (res.code == 200) {
          this.artList = res.data
        }
      })
    },
    cloumnTree () {
      cloumnTree().then(res => {
        res.data.forEach(e => {
          if (e.id == this.cloumnId) {
            if (e.children && e.children.length > 0) {
              this.containerList = e.children
              console.log(this.containerList, '111111')
            }
          }
        })
      })
    },
    handleClickSeeMore(item){
      console.log(item,'我是返回的item')
      this.$router.push({path:'/details',query:{id:item.id}})
    },
    getBanner () {
      getBanner({ cloumnId: this.cloumnId }).then(res => {
        this.list = res.data
        console.log(res, '我是返回的resid')
      })
    },
    handleClickChangeDetails(item){
      this.active = item.id
      this.getArticleChoose()
    }
  }
}
</script>

<style lang="scss" scoped>
.about{
  width: 100%;
  position: relative;
  .travel-swiper{
    height: 150px;
    width: 100%;
    //margin-bottom: 54px;
    margin-top: 50px;
  }
  .travel-banner{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .nav-header {
    position: relative;
    top: -20px;
    overflow: auto;
    margin: 0 24px;
    border-radius: 4px;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  .title{
    display: flex;
    flex-wrap: nowrap;
    //width: 327px;
    //margin: 0 auto;
    //padding: 0 10px;
    .title-box{
      float: left;
      width: 150px;
      padding: 0 8px;
      height: 44px;
      border: 1px solid rgba(151, 151, 151, 0.5);
      background: #002E73;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      flex: 0 0 100px;
    }
    .title-text{
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 20px;
      position: relative;
    }
    .line{
      position: absolute;
      bottom: 6px;
      width: 28px;
      height: 2px;
      border-radius: 1px;
      background: #FFFFFF;
    }
  }
  .title .title-box:first-child{
    border-radius: 4px 0px 0px 4px;
  }
  .title .title-box:last-child{
    border-radius: 0px 4px 4px 0px;
  }
  .main{
    padding: 0 24px;
    .art-class{
      //margin: 12px 0;
      margin-bottom: 24px;
      display: flex;
      .left-radus{
        border-radius: 4px 0 0 4px;
      }
      .right-radus{
        border-radius: 0 4px 4px 0 ;
      }
      .content-box{
        width: 204px;
        height: 119px;
        background: #F3F3F3;
        border-radius: 0px 4px 4px 0px;
        padding: 14px 16px 10px 14px;
        display: flex;
        //justify-content: space-between;
        flex-direction: column;
        .box-title{
          width: 164px;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          width: 164px;
          color: #4A4A4A;
          line-height: 22px;
          overflow: hidden;/*超出部分隐藏*/
          white-space: nowrap;/*不换行*/
          text-overflow:ellipsis;/*超出部分文字以...显示*/
        }
        .box-content{
          width: 164px;
          margin: 10px 0 7px 0;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4A4A4A;
          line-height: 17px;
          width: 164px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .box-see{
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #002E73;
          line-height: 17px;
        }
      }
      img{
        width: 124px;
        height: 119px;
        object-fit: cover
      }
    }
    .art-bottom{
      margin-top: 10px;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4A4A;
      line-height: 24px;
    }
  }

}

</style>
